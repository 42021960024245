import { useMutation } from '@apollo/react-hooks';
import type { FC } from 'react';
import React, { Fragment, useCallback, memo, useMemo } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ConfluenceEdition } from '@confluence/change-edition';
import { fg } from '@confluence/feature-gating';
import {
	SPACE_CALENDARS,
	SPACE_QUESTIONS,
	SPACE_QUESTIONS_NATIVE,
	SPACE_CALENDARS_SUBCALENDAR,
} from '@confluence/named-routes';
import { useRouteDataRef, useRouteName } from '@confluence/route-manager';
import { usePageSpaceKey } from '@confluence/page-context';
import { useSessionData } from '@confluence/session-data';
import { LoadableAfterPaint } from '@confluence/loadable';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';

import type { SpaceNavigationQuery_spaceSidebarLinks_main } from './__types__/SpaceNavigationQuery';
import { SpaceNavigationQuery } from './SpaceNavigationQuery.graphql';
import { AddSpaceLink } from './AddSpaceLink.experimentalgraphql';
import { RemoveSpaceLink } from './RemoveSpaceLink.experimentalgraphql';
import { useSidebarConfigurationListener } from './useSidebarConfigurationListener';
import { CALENDARS_KEY, QUESTIONS_KEY, QUESTIONS_NATIVE_KEY } from './webItemCompleteKeys';

type SpaceLinksProps = {
	links: SpaceNavigationQuery_spaceSidebarLinks_main[];
};

export const SpaceLinks: FC<SpaceLinksProps> = memo(({ links }) => {
	const [spaceKey] = usePageSpaceKey();
	const routeDataRef = useRouteDataRef();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isLicensed, edition } = useSessionData();
	const isQuestionsNativeEnabled = fg('cc_editions_cq_native');
	const isNav4Enabled = useIsNav4Enabled();

	const isPremiumEdition = edition === ConfluenceEdition.PREMIUM;

	const [addSpaceLink] = useMutation(AddSpaceLink, {
		refetchQueries: [
			{
				query: SpaceNavigationQuery,
				variables: { spaceKey, isLicensed },
			},
		],
	});
	const [removeSpaceLink] = useMutation(RemoveSpaceLink, {
		refetchQueries: [
			{
				query: SpaceNavigationQuery,
				variables: { spaceKey, isLicensed },
			},
		],
	});

	useSidebarConfigurationListener(addSpaceLink, removeSpaceLink);

	const sendItemClickedAnalytics = useCallback(
		(itemId: string) => () => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'navigationItem',
					actionSubjectId: 'spaceNavigationItem',
					source: 'containerNavigation',
					attributes: {
						navigationLayer: 'container',
						itemId,
						selectedItemPageContext: routeDataRef.current?.routeName,
						navVersion: isNav4Enabled ? '4' : '3',
						isPremiumEdition,
					},
				},
			}).fire();
		},
		[createAnalyticsEvent, routeDataRef, isPremiumEdition, isNav4Enabled],
	);

	const isOnSpaceCalendarsRoute = useRouteName(isOnSpaceCalendarsRouteArgs);
	const isOnSpaceQuestionsRoute = useRouteName(isOnSpaceQuestionsRouteArgs);
	const isOnSpaceQuestionsNativeRoute = useRouteName(isOnSpaceQuestionsNativeRouteArgs);

	const builtInitemsMapping = useMemo(
		() =>
			isNav4Enabled
				? {
						[CALENDARS_KEY]: <CalendarsLink onClick={sendItemClickedAnalytics('calendars')} />,
						[QUESTIONS_KEY]: (
							<QuestionsLink onClick={sendItemClickedAnalytics('questions')} isLegacy />
						),
						...(isPremiumEdition &&
							isQuestionsNativeEnabled && {
								[QUESTIONS_NATIVE_KEY]: (
									<QuestionsLink onClick={sendItemClickedAnalytics('questions')} />
								),
							}),
					}
				: {
						[CALENDARS_KEY]: (
							<TeamCalendarNavigation
								isSelected={isOnSpaceCalendarsRoute}
								onClick={sendItemClickedAnalytics('calendars')}
							/>
						),
						[QUESTIONS_KEY]: (
							<QuestionsNavigation
								isSelected={isOnSpaceQuestionsRoute}
								onClick={sendItemClickedAnalytics('questions')}
								href={SPACE_QUESTIONS.toUrl({ spaceKey })}
							/>
						),
						...(isPremiumEdition &&
							isQuestionsNativeEnabled && {
								[QUESTIONS_NATIVE_KEY]: (
									<QuestionsNavigation
										isSelected={isOnSpaceQuestionsNativeRoute}
										onClick={sendItemClickedAnalytics('questions')}
										href={SPACE_QUESTIONS_NATIVE.toUrl({ spaceKey })}
									/>
								),
							}),
					},
		[
			isOnSpaceCalendarsRoute,
			sendItemClickedAnalytics,
			isPremiumEdition,
			isOnSpaceQuestionsRoute,
			spaceKey,
			isQuestionsNativeEnabled,
			isOnSpaceQuestionsNativeRoute,
			isNav4Enabled,
		],
	);

	return (
		<Fragment>
			{links
				.filter((link): link is SpaceNavigationQuery_spaceSidebarLinks_main =>
					Boolean(link && !link.hidden),
				)
				.sort((a, b) => Number(a.position) - Number(b.position))
				.map(
					({ webItemCompleteKey }) =>
						(webItemCompleteKey && builtInitemsMapping[webItemCompleteKey]) ||
						/* Hide everything else */ null,
				)}
		</Fragment>
	);
});

const isOnSpaceCalendarsRouteArgs = {
	selector: (routeName: string | undefined) =>
		routeName === SPACE_CALENDARS.name || routeName === SPACE_CALENDARS_SUBCALENDAR.name,
};

const isOnSpaceQuestionsRouteArgs = {
	selector: (routeName: string | undefined) => routeName === SPACE_QUESTIONS.name,
};

const isOnSpaceQuestionsNativeRouteArgs = {
	selector: (routeName: string | undefined) => routeName === SPACE_QUESTIONS_NATIVE.name,
};

const TeamCalendarNavigation = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-TeamCalendarNavigation" */ './TeamCalendarNavigation'
			)
		).TeamCalendarNavigation,
});

const QuestionsNavigation = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-QuestionsNavigation" */ './QuestionsNavigation'))
			.QuestionsNavigation,
});

const CalendarsLink = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-CalendarsLink" */ './CalendarsLink'))
			.CalendarsLink,
});

const QuestionsLink = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-QuestionsLink" */ './QuestionsLink'))
			.QuestionsLink,
});
