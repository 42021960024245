import {
	SPACE_SETTINGS,
	SPACE_SETTINGS_REORDERPAGES,
	SPACE_SETTINGS_HIDDEN_PAGES,
	SPACE_SETTINGS_UNDEFINED_PAGES,
	SPACE_SETTINGS_ATTACHMENTS,
	SPACE_SETTINGS_APPLICATION_LINKS,
	SPACE_SETTINGS_RSS_FEEDS,
	SPACE_RESTRICTED_PAGES,
	SPACE_ARCHIVED_PAGES,
	SPACE_ANALYTICS,
	SPACE_ANALYTICS_PLUGIN,
	SPACE_ANALYTICS_PERMISSIONS,
	SPACE_ANALYTICS_PERMISSIONS_PLUGIN,
	FEDRAMP_SPACE_ANALYTICS_PLUGIN,
	FEDRAMP_SPACE_ANALYTICS_PERMISSIONS_PLUGIN,
	SPACE_BULK_TOOLS,
	SPACE_SETTINGS_LANDING_PAGE,
	SPACE_SETTINGS_LANDING_PAGE_ALT,
	SPACE_SETTINGS_AUTOMATION,
	SPACE_SETTINGS_ARCHIVE_SPACE,
	SPACE_SETTINGS_TRASH_SPACE,
	SPACE_SETTINGS_EDITOR_CONVERSION,
	SPACE_SETTINGS_RELATED_PAGES,
	SPACE_SETTINGS_EDIT_FEATURES,
	SPACE_SETTINGS_TEMPLATES,
	SPACE_LOOK_AND_FEEL_SETTINGS,
	SPACE_PERMISSIONS_SETTINGS,
	SPACE_PERMISSIONS_APPS,
	EDIT_SPACE_PERMISSIONS_APPS,
	SPACE_MEMBERS,
	SPACE_SETTINGS_FORGE_APPS,
	SPACE_SETTINGS_CONTENT_STATE,
	SPACE_SETTINGS_DATA_CLASSIFICATION,
	SPACE_SETTINGS_EXPORT_SPACE_UNAVAILABLE,
	SPACE_SETTINGS_EXPORT_SPACE,
	SPACE_TRASH,
	ADMIN_CENTER_SPACE,
} from '@confluence/named-routes';

import { useRouteDataRef } from './RouteState';

const SPACE_SETTINGS_SPA_ROUTES = [
	SPACE_SETTINGS,
	SPACE_ARCHIVED_PAGES,
	SPACE_ANALYTICS,
	SPACE_ANALYTICS_PLUGIN,
	SPACE_ANALYTICS_PERMISSIONS,
	SPACE_ANALYTICS_PERMISSIONS_PLUGIN,
	FEDRAMP_SPACE_ANALYTICS_PLUGIN,
	FEDRAMP_SPACE_ANALYTICS_PERMISSIONS_PLUGIN,
	SPACE_BULK_TOOLS,
	SPACE_SETTINGS_LANDING_PAGE,
	SPACE_SETTINGS_LANDING_PAGE_ALT,
	SPACE_SETTINGS_AUTOMATION,
	SPACE_SETTINGS_ARCHIVE_SPACE,
	SPACE_SETTINGS_EDITOR_CONVERSION,
	SPACE_SETTINGS_RELATED_PAGES,
	SPACE_SETTINGS_EDIT_FEATURES,
	SPACE_SETTINGS_TEMPLATES,
	SPACE_LOOK_AND_FEEL_SETTINGS,
	SPACE_PERMISSIONS_SETTINGS,
	SPACE_MEMBERS,
	SPACE_SETTINGS_FORGE_APPS,
	SPACE_SETTINGS_CONTENT_STATE,
	SPACE_SETTINGS_DATA_CLASSIFICATION,
	SPACE_SETTINGS_EXPORT_SPACE_UNAVAILABLE,
	ADMIN_CENTER_SPACE,
];

const SPACE_SETTINGS_LEGACY_ROUTES = [
	SPACE_SETTINGS_REORDERPAGES,
	SPACE_SETTINGS_HIDDEN_PAGES,
	SPACE_SETTINGS_UNDEFINED_PAGES,
	SPACE_SETTINGS_ATTACHMENTS,
	SPACE_SETTINGS_APPLICATION_LINKS,
	SPACE_SETTINGS_RSS_FEEDS,
	SPACE_RESTRICTED_PAGES,
	SPACE_SETTINGS_TRASH_SPACE,
	SPACE_PERMISSIONS_APPS,
	EDIT_SPACE_PERMISSIONS_APPS,
	SPACE_SETTINGS_EXPORT_SPACE,
	SPACE_TRASH,
];

/**
 * Custom hook that checks if the current route according to RouteState matches a named Space settings route.
 * Note: The below logic matches named routes against the URL, not Route name, to account for legacy pages.
 * @returns true if the current href matches a named route, false otherwise.
 */
export const useIsSpaceSettingsRoute = () => {
	const routeDataRef = useRouteDataRef();
	const currentHref = routeDataRef.current?.href;
	if (currentHref) {
		const isSpaceSettingsSPARoute = SPACE_SETTINGS_SPA_ROUTES.find((route) =>
			route.match(currentHref),
		);
		const isSpaceSettingsLegacyRoute = SPACE_SETTINGS_LEGACY_ROUTES.find((route) =>
			route.match(currentHref),
		);
		return !!(isSpaceSettingsSPARoute || isSpaceSettingsLegacyRoute);
	} else {
		return false;
	}
};
