import { useMemo, useState } from 'react';

import {
	confluenceSessionStorageInstance as sessionStorage,
	keys as sessionStorageKeys,
} from '@confluence/storage-manager';

export const useSpacePersistentExpansion = (
	spaceKey: string,
	sectionName: string,
	collapsedByDefault = false,
) => {
	const [manualExpansionSpaceKey, setManualExpansionSpaceKey] = useState<string | undefined>();
	const [isExpandedManually, setIsExpandedManually] = useState<boolean | undefined>();

	const isDefaultExpanded = useMemo(
		() => getExpandedStateBySpace(spaceKey, sectionName, collapsedByDefault),
		[spaceKey, sectionName, collapsedByDefault],
	);

	const hasManualExpansion =
		isExpandedManually !== undefined && manualExpansionSpaceKey === spaceKey;
	const isExpanded = hasManualExpansion ? isExpandedManually : isDefaultExpanded;

	const onExpansionToggle = (newIsExpanded: boolean) => {
		setManualExpansionSpaceKey(spaceKey);
		setIsExpandedManually(newIsExpanded);
		updateExpandedStateBySpace(spaceKey, sectionName, newIsExpanded);
	};

	return {
		isExpanded,
		onExpansionToggle,
	};
};

const getExpandedStateBySpace = (
	spaceKey: string,
	sectionName: string,
	collapsedByDefault: boolean,
) => {
	if (sessionStorage.doesContain(sessionStorageKeys.SPACE_NAV_SECTIONS_EXPANDED_STATES)) {
		const sideNavExpansionStates = sessionStorage.getItem(
			sessionStorageKeys.SPACE_NAV_SECTIONS_EXPANDED_STATES,
		);
		if (sideNavExpansionStates[spaceKey]?.[sectionName] !== undefined) {
			return sideNavExpansionStates[spaceKey][sectionName];
		}
	}
	return !collapsedByDefault;
};

const updateExpandedStateBySpace = (spaceKey: string, sectionName: string, isExpanded: boolean) => {
	const sideNavExpansionStates =
		sessionStorage.getItem(sessionStorageKeys.SPACE_NAV_SECTIONS_EXPANDED_STATES) || {};

	sessionStorage.setItem(sessionStorageKeys.SPACE_NAV_SECTIONS_EXPANDED_STATES, {
		...sideNavExpansionStates,
		[spaceKey]: {
			...sideNavExpansionStates[spaceKey],
			[sectionName]: isExpanded,
		},
	});
};
