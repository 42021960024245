import { LoadablePaint, LoadableAfterPaint } from '@confluence/loadable';

export const SpaceSettingsNavigationV2Portal = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-navigationSpaceSettingsNavigationPortal" */ './navigation/SpaceSettingsNavigationPortal'
			)
		).SpaceSettingsNavigationPortal,
	name: 'SpaceSettingsNavigationPortal',
});

export const SpaceSettingsSidebarLinks = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-sidebarSpaceSettingsSidebarLinks" */ './sidebar/SpaceSettingsSidebarLinks'
			)
		).SpaceSettingsSidebarLinks,
	name: 'SpaceSettingsSidebarLinks',
});

export const SpaceSettingsV2ForgeApp = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-forge-appsForgeApp" */ './forge-apps/ForgeApp'))
			.ForgeApp,
	name: 'SpaceSettingsV2ForgeApp',
});

export const SpaceSettingsV2LandingPage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-landing-pageLandingPage" */ './landing-page/LandingPage'
			)
		).LandingPage,
	name: 'SpaceSettingsV2LandingPage',
});

export const ContentStateSettings = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-pagesContentStateSettings" */ './pages/ContentStateSettings'
			)
		).ContentStateSettings,
	name: 'ContentStateSettings',
});

export { RelatedPagesSpaceSettings } from './pages/RelatedPagesSpaceSettings';

export const ArchiveSpace = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-pagesArchiveSpace" */ './pages/ArchiveSpace'))
			.ArchiveSpace,
	name: 'ArchiveSpace',
});

export const EditorConversion = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-pagesArchiveSpace" */ './pages/EditorConversion'))
			.EditorConversion,
	name: 'EditorConversion',
});

export const DataClassificationSpaceSettings = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-dataClassificationSpaceSettings" */ './pages/DataClassificationSpaceSettings'
			)
		).DataClassificationSpaceSettings,
	name: 'DataClassificationSpaceSettings',
});

export const ExportSpaceUnavailable = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-exportSpaceUnavailable" */ './pages/ExportSpaceUnavailable'
			)
		).ExportSpaceUnavailable,
	name: 'ExportSpaceUnavailable',
});

export const EditFeatures = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-pagesEditFeatures" */ './pages/EditFeatures'))
			.EditFeatures,
	name: 'EditFeatures',
});

export const LazyPdfExportPage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PdfExportPage" */ './pages/look-and-feel/pdf-export/PdfExportPage'
			)
		).PdfExportPage,
	name: 'PdfExportPage',
});

export const LazyPageLayoutPage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PageLayoutPage" */ './pages/look-and-feel/page-layout/PageLayoutPage'
			)
		).PageLayoutPage,
	name: 'PageLayoutPage',
});

export const LazyThemesPage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ThemesPage" */ './pages/look-and-feel/themes/ThemesPage'
			)
		).ThemesPage,
	name: 'ThemesPage',
});

export const LazyTemplatesPage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-TemplatesPage" */ './pages/look-and-feel/templates/TemplatesAndBlueprintsPage'
			)
		).TemplatesAndBlueprintsPage,
	name: 'TemplatesPage',
});

export const SpaceSettingsSideNavigation = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-SpaceSettingsSideNavigation" */ './side-navigation/SpaceSettingsSideNavigation'
			)
		).SpaceSettingsSideNavigation,
	name: 'SpaceSettingsSideNavigation',
});
